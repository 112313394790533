/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {separadorMiles} from "../../Global/helpers";

export function VentasWidget({
  className,
  baseColor = "primary",
  widgetHeight = "150px",
  entities,
  loading
}) {


  return (
    <>
        <div className={`card card-custom bg-${baseColor} ${className}`} style={{ height: widgetHeight }} >
            <div className="card-body" style={{padding:"10px"}}>
                <div className="text-left">
                    <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")} />
                        {loading  && <i className="fas fa-2x fa-spinner fa-spin text-white"></i> }
                    </span>
                    <div className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-3`} >
                        {entities.total ? '$' + separadorMiles(entities.total) : ""}
                    </div>
                    <a href="#" className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1`} >
                        Ventas
                    </a>
                </div>
            </div>
        </div>

    </>
  );
}
