import axios from "axios";

export const DASHBOARD_URL =`${process.env.REACT_APP_API_URL}/dashboard`;

// http://localhost:3000/api/dashboard/orders/sales?filter={"business_unit_id":1,"order_creation_from":"20220401","order_creation_to":"20220410"}


let cancelToken;

export function getSales(params){
    return (async () => {
        try {
            if (typeof cancelToken != typeof undefined) {
              cancelToken.cancel("Cancelado por new Request.")
            }
            cancelToken = axios.CancelToken.source();

            const resp = await axios.get(DASHBOARD_URL + '/orders/sales', { params, cancelToken: cancelToken.token });

            return resp;

        } catch (err) {
          
            if (axios.isCancel(err)) {
              //console.log('Request canceled');
            } else {
              // handle error
            }
            throw err;
        }
    })();
}
  

//http://localhost:3000/api/dashboard/orders/product/ranking/count?filter={"business_unit_id":1,"order_creation_from":"20220401","order_creation_to":"20220410"}
export function getRanking(params){
    return (async () => {
        try {
            if (typeof cancelToken != typeof undefined) {
              cancelToken.cancel("Cancelado por new Request.")
            }
            cancelToken = axios.CancelToken.source();

            const resp = await axios.get(DASHBOARD_URL + '/orders/product/ranking/count', { params, cancelToken: cancelToken.token });

            return resp;

        } catch (err) {
          
            if (axios.isCancel(err)) {
              //console.log('Request canceled');
            } else {
              // handle error
            }
            throw err;
        }
    })();
}

//http://localhost:3000/api/dashboard/orders/product/ranking/customer?filter={"iso_id":"AR-X","business_unit_id":1,"order_creation_from":"20220401","order_creation_to":"20220410"}
export function getRankingCustomers(params){
  return (async () => {
      try {
          if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Cancelado por new Request.")
          }
          cancelToken = axios.CancelToken.source();

          const resp = await axios.get(DASHBOARD_URL + '/orders/product/ranking/customer', { params, cancelToken: cancelToken.token });

          return resp;

      } catch (err) {
        
          if (axios.isCancel(err)) {
            //console.log('Request canceled');
          } else {
            // handle error
          }
          throw err;
      }
  })();
}


