import axios from "axios";

export const PRODUCTS_URL =`${process.env.REACT_APP_API_URL}/product`;

let cancelToken;

export function getProducts(params) {
    return (async () => {
        try {
            if (typeof cancelToken != typeof undefined) {
              cancelToken.cancel("Cancelado por new Request.")
            }
            cancelToken = axios.CancelToken.source();

            const resp = await axios.get(PRODUCTS_URL, { params, cancelToken: cancelToken.token });

            return resp;

        } catch (err) {
          
            if (axios.isCancel(err)) {
              //console.log('Request canceled');
            } else {
              // handle error
            }
            throw err;
        }
    })();
}

export function getProductById(id) {
  return axios.get(`${PRODUCTS_URL}/${id}`);
}



export function createProduct(product){
  return axios.post(`${PRODUCTS_URL}`,  product );
}


export function updateProduct(product){
  return axios.put(`${PRODUCTS_URL}/${product.id}`,  product );
}

export function setArticle(data){
  return axios.post(`${PRODUCTS_URL}/${data.id}/set-article`,  {reg:data.reg} );
}

export function unsetArticle(product_id){
  return axios.post(`${PRODUCTS_URL}/${product_id}/unset-article` );
}

