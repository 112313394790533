import axios from "axios";

export const PRINT_URL =`${process.env.REACT_APP_API_URL}/print`;

// CREATE =>  POST: add a new order to the server
export function printGuide(obTransfer) {
  return axios.post(PRINT_URL + '/wave/' + obTransfer.wave_id + '/guides', {orders:obTransfer.orders}, {responseType:"blob"});
}

export function printGuideFromPharmacy(order_id) {
  return axios.post(PRINT_URL + '/order/' + order_id, {}, {responseType:"blob"});
}

export async function printTickets(obTransfer) {


    for(var i=0;i<obTransfer.orders.length;i++){
      var order = obTransfer.orders[i];
      var resp = await axios.post(PRINT_URL + '/wave/' + obTransfer.wave_id + '/tickets', {orders:[order]})

      
      // await axios.post(
      //   "https://192.168.17.10:5003/ticket",
      //   resp.data.data
      // )
      //await axios.post(
      //  "https://10.100.41.15:5003/ticket",
      //  resp.data.data
      //)
      await axios.post(
        "https://PROD-03-PS.pharmatrans.local:5003/ticket",
        resp.data.data
      )

    }

}


export function printWaybill(waybill_id) {
  return axios.post(PRINT_URL + '/waybill/' + waybill_id,{},  {responseType:"blob"});
}

export function printConsolidated( wave_id) {
  return axios.post(PRINT_URL + '/wave/' + wave_id + '/consolidated',{}, {responseType:"blob"});
}


export function printTestTicket(obTransfer) {
  return axios.post(
    "http://10.0.1.15/ticket",
    {
      obTransfer
    }

  );
}

export function printDepotMovement( movement_id) {
  return axios.post(PRINT_URL + '/stock-depot-mov/' + movement_id,{}, {responseType:"blob"});
}